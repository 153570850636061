import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router';
import _ from 'underscore';
import Icon from '../../elements/Icon';

import './navbuttons.css';

export default function () {
    const { buttons, markDayDoneActive, toggleMarkDayDone, shouldShowMarkDayDoneButton } = this.props;

    return (
        <div className="navbuttons">
            <Row>
                <Col span={12}>
                    {!_.isNull(buttons.previousImageUrl) && !markDayDoneActive
                        ? (
                            <Link ref="previousImageButton" to={buttons.previousImageUrl} className="btn-empty">
                                <Icon name="arrowback" />
                                <span>Back</span>
                            </Link>
                        )
                        : !markDayDoneActive && (
                            <button ref="previousImageButton" type="button" className="btn-empty disabled">
                                <Icon name="arrowback" />
                                <span>Back</span>
                            </button>
                        )}
                    {markDayDoneActive ? (
                        <button
                            type="button"
                            className="btn-empty"
                            onClick={() => {
                                if (markDayDoneActive) {
                                    toggleMarkDayDone();
                                }
                            }}>
                            <Icon name="arrowback" />
                            <span>Back</span>
                        </button>
                    ) : null}
                </Col>
                <Col span={12}>
                    {!_.isNull(buttons.nextImageUrl)
                        ? (
                            <Link ref="nextImageButton" to={buttons.nextImageUrl} className="btn-empty">
                                <span>Next</span>
                                <Icon name="arrownext-bt" />
                            </Link>
                        )
                        : !buttons.isLastImage || !shouldShowMarkDayDoneButton && (
                            <button ref="nextImageButton" type="button" className="btn-empty disabled">
                                <span>Next</span>
                                <Icon name="arrownext-bt" />
                            </button>
                        )}
                    {buttons.isLastImage && shouldShowMarkDayDoneButton ? (
                        <button
                            type="button"
                            className="btn-empty"
                            onClick={() => {
                                if (!markDayDoneActive) {
                                    toggleMarkDayDone();
                                }
                            }}>
                            <span>Next</span>
                            <Icon name="arrownext-bt" />
                        </button>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
}
