/* eslint-disable no-mixed-operators, no-nested-ternary */
/* global window */
import React from 'react';
import _ from 'underscore';
import { Modal, Icon, Spin } from 'antd';

import CustomIcon from '../../elements/Icon';
import ModalContent from './ModalContent';
import { ROLE_ACTOR, ROLE_ADMIN, ROLE_AGENT, ROLE_RESELLER, ROLE_RESELLER_ADMIN, ROLE_ROOT_ADMIN, ROLE_VIEWER } from '../../core/utils/value';

const { CAPTION_MODE } = require('../../core/constants').default;

export default function () {
    const {
        selectedProduction, isFetching, user, selectedProductionDayImage, params, imageCaptionModeOn, imageTagModeOn,
        productionUserStats, userStatsIsFetching
    } = this.props;

    const productionSlug = window.location.pathname.split('/')[2] || 'unknown';

    let currentUser = {};
    let filteredUserStats = [];
    let killedImagesLink = null;
    let productionTotalImages = 0;
    let userSoloTotalImages = 0;
    let userGroupTotalImages = 0;
    let lowerGroupTotal = 0;
    let upperGroupTotal = 0;
    let approveSelectedImages = 0;
    let totalUnkilledImages = 0;

    let userSoloKillBox = null;
    let userGroupKillBox = null;
    let userUpperGroupKillBox = null;

    const isSingleImagePage = !!(params && params.production && params.day && params.image);

    // if (!isFetching && !_.isNull(selectedProduction) && selectedProduction.productionDays) {
    if (!userStatsIsFetching && !_.isNull(productionUserStats) && productionUserStats.usersStat) {
        ({ currentUser } = productionUserStats);
        filteredUserStats = productionUserStats.usersStat;

        if (!currentUser) {
            return false;
        }

        if (currentUser.user_type === ROLE_RESELLER) {
            filteredUserStats = _.filter(filteredUserStats, (filteredUserStat) => filteredUserStat.role !== ROLE_ROOT_ADMIN);
        } else if (currentUser.user_type === ROLE_AGENT) {
            filteredUserStats = _.filter(filteredUserStats, (filteredUserStat) => filteredUserStat.role !== ROLE_ROOT_ADMIN && filteredUserStat.role !== ROLE_RESELLER_ADMIN);
        }
        killedImagesLink = `/production/${productionSlug}/kill-list`;
        productionTotalImages = productionUserStats?.totalProductionImages?.totalImages;

        userSoloTotalImages = productionUserStats?.totalProductionImages?.soloImages;

        userGroupTotalImages = productionUserStats?.totalProductionImages?.groupImages;

        approveSelectedImages = productionUserStats?.totalProductionImages?.totalApproveSelectedImages;

        totalUnkilledImages = productionUserStats?.totalProductionImages?.totalUnkilledImages;
        if (currentUser.allowance_type === 1) {
            if (currentUser.soloKillLimit === 0) {
                userSoloKillBox = '(S: UL)';
            } else if (currentUser.soloKillLimit > 0 && userSoloTotalImages === 0) {
                userSoloKillBox = '(S: 0)';
            } else {
                userSoloKillBox = `(S: ${Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100)}%)`;
            }

            if (currentUser.groupKillLimit === 0) {
                userGroupKillBox = '(G: UL)';
            } else if (currentUser.groupKillLimit > 0 && userGroupTotalImages === 0) {
                userGroupKillBox = '(G: 0)';
            } else {
                userGroupKillBox = `(G: ${Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages) * 100)}%)`;
            }
        } else {
            const { imageCountByTagNumber } = productionUserStats.totalProductionImages;
            const countByTagNum = Object.keys(imageCountByTagNumber).map((key) => ({ key: parseInt(key, 10), value: imageCountByTagNumber[key] }));
            const limit = parseInt(currentUser.upper_group_threshold, 10);
            lowerGroupTotal = countByTagNum.reduce((acc, v) => (acc + (v.key < limit && v.key > 1 ? v.value : 0)), 0);
            upperGroupTotal = countByTagNum.reduce((acc, v) => (acc + (v.key >= limit ? v.value : 0)), 0);

            const soloPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
            const lowerPercentage = Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * lowerGroupTotal) * 100);
            const upperPercentage = Math.ceil(currentUser.upperGroupKills / Math.ceil((currentUser.upperGroupKillLimit / 100) * upperGroupTotal) * 100);

            if (currentUser.soloKillLimit === 0) {
                userSoloKillBox = '(S: UL)';
            } else if (currentUser.soloKillLimit > 0 && userSoloTotalImages === 0) {
                userSoloKillBox = '(S: 0)';
            } else {
                userSoloKillBox = `(S: ${soloPercentage}%)`;
            }

            // Lower
            if (currentUser.groupKillLimit === 0) {
                userGroupKillBox = '(LG: UL)';
            } else if (currentUser.groupKillLimit > 0 && userGroupTotalImages === 0) {
                userGroupKillBox = '(LG: 0)';
            } else {
                userGroupKillBox = `(LG: ${lowerPercentage}%)`;
            }

            // Upper
            if (currentUser.upperGroupKillLimit === 0) {
                userUpperGroupKillBox = '(UG: UL)';
            } else if (currentUser.groupKillLimit > 0 && userGroupTotalImages === 0) {
                userUpperGroupKillBox = '(UG: 0)';
            } else {
                userUpperGroupKillBox = `(UG: ${upperPercentage}%)`;
            }
        }
    }
    const { visibleProductionInfo } = this.state;


    return (
        <div className="header__info">
            {isSingleImagePage && currentUser && currentUser.AllowImageDownload !== 0
                ? (
                    <span onClick={() => this.downloadImage(selectedProductionDayImage.production.slug, selectedProductionDayImage.production_day.slug, selectedProductionDayImage.name)}>
                        <Icon
                            type="download"
                            style={{ fontSize: '30px', marginLeft: '10px' }} />
                    </span>
                )
                : null}
            {currentUser.user_type === ROLE_ACTOR
                ? (
                    <span style={{ fontSize: userUpperGroupKillBox !== null ? '0.8em' : 'unset' }}>
                        {userSoloKillBox}
                        <br />
                        {userGroupKillBox}
                        {userUpperGroupKillBox !== null
                            ? <br />
                            : null}
                        {userUpperGroupKillBox !== null
                            ? userUpperGroupKillBox
                            : null}
                    </span>
                )
                : null}
            {/* This caption function is comment as it is not finished yet  */}
            {/* {CAPTION_MODE != 'false' && currentUser.user_type === ROLE_ACTOR && isSingleImagePage === true && selectedProductionDayImage
                ? (
                    <span onClick={() => this.toggleImageCaptionMode()}>
                        {imageCaptionModeOn
                            ? (
                                <Icon
                                    type="message"
                                    theme="filled"
                                    style={{ fontSize: '30px', marginLeft: '10px', color: '#52c41a' }} />
                            )
                            : (
                                <Icon
                                    type="message"
                                    style={{ fontSize: '30px', marginLeft: '10px' }} />
                            )}
                    </span>
                )
                : null} */}
            {currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER && isSingleImagePage === true && selectedProductionDayImage
                ? (
                    <span onClick={() => this.toggleImageSelect(selectedProductionDayImage.production.slug, selectedProductionDayImage.production_day.slug, selectedProductionDayImage.name)}>
                        {selectedProductionDayImage.is_selected && parseInt(selectedProductionDayImage.is_selected, 10) === 1
                            ? (
                                <Icon
                                    type="star"
                                    theme="filled"
                                    style={{ fontSize: '30px', marginLeft: '10px', color: '#ffb424' }} />
                            )
                            : (
                                <Icon
                                    type="star"
                                    style={{ fontSize: '30px', marginLeft: '10px' }} />
                            )}
                    </span>
                )
                : null}
            <span onClick={() => {
                const { actions } = this.props;
                this.setState({ visibleProductionInfo: true });
                actions.getProductionUserStatsRequest({
                    slug: productionSlug
                });
            }}>
                <CustomIcon name="info" />
            </span>
            <Modal
                width="fit-content"
                title="Image Summary"
                footer={null}
                style={{ textAlign: 'center' }}
                visible={visibleProductionInfo}
                onOk={this.handleProductionInfoOk}
                onCancel={this.handleProductionInfoCancel}>
                <Spin spinning={userStatsIsFetching}>
                    <ModalContent
                        type={
                            currentUser.user_type === ROLE_ADMIN || currentUser.user_type === ROLE_RESELLER
                                ? 'agent'
                                : (
                                    currentUser.user_type === ROLE_ACTOR
                                        ? (currentUser.allowanceType > 1
                                            ? 'upper'
                                            : 'user')
                                        : currentUser.user_type === ROLE_AGENT
                                            ? 'agent'
                                            : (currentUser.user_type === ROLE_VIEWER
                                                ? 'viewer'
                                                : null))
                        }
                        currentUser={currentUser}
                        userSoloTotalImages={userSoloTotalImages}
                        userGroupTotalImages={userGroupTotalImages}
                        productionTotalImages={productionTotalImages}
                        approveSelectedImages={approveSelectedImages}
                        totalUnkilledImages={totalUnkilledImages}
                        killedImagesLink={killedImagesLink}
                        filteredUserStats={filteredUserStats}
                        userLowerGroupTotalImages={lowerGroupTotal}
                        userUpperGroupTotalImages={upperGroupTotal} />
                </Spin>
            </Modal>
        </div>
    );
}
