import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_USER_PRODUCTION_REQUEST: null,
    GET_USER_PRODUCTION_SUCCESS: null,
    GET_USER_PRODUCTION_FAILURE: null,

    GET_USER_SINGLE_PRODUCTION_REQUEST: null,
    GET_USER_SINGLE_PRODUCTION_SUCCESS: null,
    GET_USER_SINGLE_PRODUCTION_FAILURE: null,

    GET_USER_SINGLE_PRODUCTION_DAY_REQUEST: null,
    GET_USER_SINGLE_PRODUCTION_DAY_SUCCESS: null,
    GET_USER_SINGLE_PRODUCTION_DAY_FAILURE: null,

    GET_USER_SINGLE_IMAGE_REQUEST: null,
    GET_USER_SINGLE_IMAGE_SUCCESS: null,
    GET_USER_SINGLE_IMAGE_FAILURE: null,

    USER_KILL_IMAGE_REQUEST: null,
    USER_KILL_IMAGE_SUCCESS: null,
    USER_KILL_IMAGE_FAILURE: null,

    USER_MARK_DAY_DONE_REQUEST: null,
    USER_MARK_DAY_DONE_SUCCESS: null,
    USER_MARK_DAY_DONE_FAILURE: null,

    GET_ALL_RESELLERS_REQUEST: null,
    GET_ALL_RESELLERS_SUCCESS: null,
    GET_ALL_RESELLERS_FAILURE: null,

    SEND_ACCESS_REQUEST_REQUEST: null,
    SEND_ACCESS_REQUEST_SUCCESS: null,
    SEND_ACCESS_REQUEST_FAILURE: null,

    GET_ALL_ACTORS_REQUEST: null,
    GET_ALL_ACTORS_SUCCESS: null,
    GET_ALL_ACTORS_FAILURE: null,

    ADD_TAG_USER_REQUEST: null,
    ADD_TAG_USER_SUCCESS: null,
    ADD_TAG_USER_FAILURE: null,

    REMOVE_TAG_USER_REQUEST: null,
    REMOVE_TAG_USER_SUCCESS: null,
    REMOVE_TAG_USER_FAILURE: null,

    GET_DOWNLOAD_QUEUE_REQUEST: null,
    GET_DOWNLOAD_QUEUE_SUCCESS: null,
    GET_DOWNLOAD_QUEUE_FAILURE: null,

    GET_DOWNLOAD_FILE_REQUEST: null,
    GET_DOWNLOAD_FILE_SUCCESS: null,
    GET_DOWNLOAD_FILE_FAILURE: null,

    GET_DOWNLOAD_IMAGE_REQUEST: null,
    GET_DOWNLOAD_IMAGE_SUCCESS: null,
    GET_DOWNLOAD_IMAGE_FAILURE: null,

    SET_SELECTED_ACTOR_REQUEST: null,
    SET_SELECTED_ACTOR_SUCCESS: null,

    SET_IMAGE_SELECTED_FLAG_REQUEST: null,
    SET_IMAGE_SELECTED_FLAG_SUCCESS: null,
    SET_IMAGE_SELECTED_FLAG_FAILURE: null,

    RESET_SELECTED_ACTOR_REQUEST: null,

    UPDATE_IMAGE_CAPTION_REQUEST: null,
    UPDATE_IMAGE_CAPTION_SUCCESS: null,
    UPDATE_IMAGE_CAPTION_FAILURE: null,

    PRELOAD_IMAGES_REQUEST: null,
    PRELOAD_IMAGES_SUCCESS: null,
    PRELOAD_IMAGES_FAILURE: null,
    RESET_PRELOAD_IMAGES_REQUEST: null,
    RESET_SELECTED_PRODUCTION_DAY_IMAGE_REQUEST: null,

    UPDATE_IMAGE_TAG_REQUEST: null,
    UPDATE_IMAGE_TAG_SUCCESS: null,
    UPDATE_IMAGE_TAG_FAILURE: null,

    ADD_TAG_COMBINATION_REQUEST: null,
    GET_PRODUCTION_USER_STATS_REQUEST: null,
    GET_PRODUCTION_USER_STATS_SUCCESS: null,
    GET_PRODUCTION_USER_STATS_FAILURE: null,

    SYNC_LOCAL_IMAGES_REQUEST: null,
});

export const {
    getUserProductionRequest,
    getUserProductionSuccess,
    getUserProductionFailure,
} = createActions(
    constants.GET_USER_PRODUCTION_REQUEST,
    constants.GET_USER_PRODUCTION_SUCCESS,
    constants.GET_USER_PRODUCTION_FAILURE,
);

export const {
    getUserSingleProductionRequest,
    getUserSingleProductionSuccess,
    getUserSingleProductionFailure,
} = createActions(
    constants.GET_USER_SINGLE_PRODUCTION_REQUEST,
    constants.GET_USER_SINGLE_PRODUCTION_SUCCESS,
    constants.GET_USER_SINGLE_PRODUCTION_FAILURE,
);

export const {
    getUserSingleProductionDayRequest,
    getUserSingleProductionDaySuccess,
    getUserSingleProductionDayFailure,
} = createActions(
    constants.GET_USER_SINGLE_PRODUCTION_DAY_REQUEST,
    constants.GET_USER_SINGLE_PRODUCTION_DAY_SUCCESS,
    constants.GET_USER_SINGLE_PRODUCTION_DAY_FAILURE,
);

export const {
    getUserSingleImageRequest,
    getUserSingleImageSuccess,
    getUserSingleImageFailure,
} = createActions(
    constants.GET_USER_SINGLE_IMAGE_REQUEST,
    constants.GET_USER_SINGLE_IMAGE_SUCCESS,
    constants.GET_USER_SINGLE_IMAGE_FAILURE,
);

export const {
    userKillImageRequest,
    userKillImageSuccess,
    userKillImageFailure,
} = createActions(
    constants.USER_KILL_IMAGE_REQUEST,
    constants.USER_KILL_IMAGE_SUCCESS,
    constants.USER_KILL_IMAGE_FAILURE,
);

export const {
    userMarkDayDoneRequest,
    userMarkDayDoneSuccess,
    userMarkDayDoneFailure,
} = createActions(
    constants.USER_MARK_DAY_DONE_REQUEST,
    constants.USER_MARK_DAY_DONE_SUCCESS,
    constants.USER_MARK_DAY_DONE_FAILURE,
);

export const {
    getAllResellersRequest,
    getAllResellersSuccess,
    getAllResellersFailure,
} = createActions(
    constants.GET_ALL_RESELLERS_REQUEST,
    constants.GET_ALL_RESELLERS_SUCCESS,
    constants.GET_ALL_RESELLERS_FAILURE,
);

export const {
    sendAccessRequestRequest,
    sendAccessRequestSuccess,
    sendAccessRequestFailure,
} = createActions(
    constants.SEND_ACCESS_REQUEST_REQUEST,
    constants.SEND_ACCESS_REQUEST_SUCCESS,
    constants.SEND_ACCESS_REQUEST_FAILURE,
);

export const {
    getAllActorsRequest,
    getAllActorsSuccess,
    getAllActorsFailure,
} = createActions(
    constants.GET_ALL_ACTORS_REQUEST,
    constants.GET_ALL_ACTORS_SUCCESS,
    constants.GET_ALL_ACTORS_FAILURE,
);

export const {
    addTagUserRequest,
    addTagUserSuccess,
    addTagUserFailure,
} = createActions(
    constants.ADD_TAG_USER_REQUEST,
    constants.ADD_TAG_USER_SUCCESS,
    constants.ADD_TAG_USER_FAILURE,
);

export const {
    removeTagUserRequest,
    removeTagUserSuccess,
    removeTagUserFailure,
} = createActions(
    constants.REMOVE_TAG_USER_REQUEST,
    constants.REMOVE_TAG_USER_SUCCESS,
    constants.REMOVE_TAG_USER_FAILURE,
);

export const {
    getDownloadQueueRequest,
    getDownloadQueueSuccess,
    getDownloadQueueFailure,
} = createActions(
    constants.GET_DOWNLOAD_QUEUE_REQUEST,
    constants.GET_DOWNLOAD_QUEUE_SUCCESS,
    constants.GET_DOWNLOAD_QUEUE_FAILURE,
);

export const {
    getDownloadFileRequest,
    getDownloadFileSuccess,
    getDownloadFileFailure,
} = createActions(
    constants.GET_DOWNLOAD_FILE_REQUEST,
    constants.GET_DOWNLOAD_FILE_SUCCESS,
    constants.GET_DOWNLOAD_FILE_FAILURE,
);

export const {
    getDownloadImageRequest,
    getDownloadImageSuccess,
    getDownloadImageFailure,
} = createActions(
    constants.GET_DOWNLOAD_IMAGE_REQUEST,
    constants.GET_DOWNLOAD_IMAGE_SUCCESS,
    constants.GET_DOWNLOAD_IMAGE_FAILURE,
);

export const {
    setSelectedActorRequest,
    setSelectedActorSuccess,
} = createActions(
    constants.SET_SELECTED_ACTOR_REQUEST,
    constants.SET_SELECTED_ACTOR_SUCCESS,
);

export const {
    setImageSelectedFlagRequest,
    setImageSelectedFlagSuccess,
    setImageSelectedFlagFailure,
} = createActions(
    constants.SET_IMAGE_SELECTED_FLAG_REQUEST,
    constants.SET_IMAGE_SELECTED_FLAG_SUCCESS,
    constants.SET_IMAGE_SELECTED_FLAG_FAILURE,
);

export const {
    resetSelectedActorRequest,
} = createActions(
    constants.RESET_SELECTED_ACTOR_REQUEST,
);

export const {
    updateImageCaptionRequest,
    updateImageCaptionSuccess,
    updateImageCaptionFailure,
} = createActions(
    constants.UPDATE_IMAGE_CAPTION_REQUEST,
    constants.UPDATE_IMAGE_CAPTION_SUCCESS,
    constants.UPDATE_IMAGE_CAPTION_FAILURE,
);

export const {
    preloadImagesRequest,
    preloadImagesSuccess,
    preloadImagesFailure,
    resetPreloadImagesRequest,
    resetSelectedProductionDayImageRequest,
} = createActions(
    constants.PRELOAD_IMAGES_REQUEST,
    constants.PRELOAD_IMAGES_SUCCESS,
    constants.PRELOAD_IMAGES_FAILURE,
    constants.RESET_PRELOAD_IMAGES_REQUEST,
    constants.RESET_SELECTED_PRODUCTION_DAY_IMAGE_REQUEST,
);

export const {
    getProductionUserStatsRequest,
    getProductionUserStatsSuccess,
    getProductionUserStatsFailure,
} = createActions(
    constants.GET_PRODUCTION_USER_STATS_REQUEST,
    constants.GET_PRODUCTION_USER_STATS_SUCCESS,
    constants.GET_PRODUCTION_USER_STATS_FAILURE,
);

export const {
    syncLocalImagesRequest,
} = createActions(
    constants.SYNC_LOCAL_IMAGES_REQUEST,
);
