/* global document: true */
import React from 'react';
import _ from 'underscore';
import { browserHistory } from 'react-router';
import DOM from './navbuttons';

export default class Navbuttons extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        document.addEventListener('keydown', this.KeyControlledNavigation);
    }

    KeyControlledNavigation = (e) => {
        const key = e.which || e.keyCode;
        if (key === 37) {
            // Show previous image
            const previousLink = this.refs.previousImageButton && this.refs.previousImageButton.props && this.refs.previousImageButton.props.to ? this.refs.previousImageButton.props.to : null;
            
            // check if mark day done button is active
            const { markDayDoneActive } = this.props;
        
            if (!_.isUndefined(previousLink) && !_.isNull(previousLink) && !markDayDoneActive) {
                browserHistory.push(previousLink);
            }

            if (markDayDoneActive) {
                this.props.toggleMarkDayDone();
            }
        }
        if (key === 39) {
            // Show next image
            const nextLink = this.refs.nextImageButton && this.refs.nextImageButton.props && this.refs.nextImageButton.props.to ? this.refs.nextImageButton.props.to : null;

            // get isLastImage from buttons
            const isLastImage = this.props.buttons && this.props.buttons.isLastImage ? this.props.buttons.isLastImage : false;
            const { markDayDoneActive, shouldShowMarkDayDoneButton } = this.props;
        
            if (!_.isUndefined(nextLink) && !_.isNull(nextLink) && !isLastImage) {
                browserHistory.push(nextLink);
            }

            if (isLastImage && !markDayDoneActive && shouldShowMarkDayDoneButton) {
                this.props.toggleMarkDayDone();
            }
        }
    }

    render() {
        return this.view();
    }
}
