import React from 'react';
import {
    Form, Input, Button, Row, Col, Modal
} from 'antd';
import Helmet from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const FormItem = Form.Item;

export default function () {
    const { getFieldDecorator } = this.props.form;
    const { showModal, showEmailModal } = this.state;
    return (
        <div className="page-wrap">
            <Helmet title="Forgot Password" />
            <Header title="Forgot Password" handleLogout={null} />
            <div className="container">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <Row style={{ marginTop: '40px', marginBottom: '10px' }}>
                        <Col xs={24}>
                            <FormItem label="Email or Username">
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your E-mail or Username'
                                        }
                                    ]
                                })(<Input />)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <FormItem>
                                <Button type="red" htmlType="submit">
                                    Send Request
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Footer />
                <Modal
                    centered
                    visible={showModal}
                    onOk={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}>
                    <p>Thank you for requesting a password reset. A member of the Image Approvals team will look into this for you and be back in touch shortly.</p>
                </Modal>
                <Modal
                    centered
                    visible={showEmailModal}
                    onOk={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}>
                    <p>You have been emailed a link to change your password. If you do not see it, please check your spam.</p>
                </Modal>
            </div>
        </div>
    );
}
