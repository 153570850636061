import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as userActions from '../../core/user/userActions';
import * as s3LinksActions from '../../core/s3Links/s3LinksActions';
import DOM from './linkUploadForm';

class LinkUploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            showModal: false,
            showSuccessModal: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { isLastFileQueue, isChecked } = this.props;
        
        if (isLastFileQueue && isChecked.isChecked 
            && (!prevProps.isLastFileQueue || !prevProps.isChecked.isChecked)) {
            this.handleShowConfirm();
        }
    }

    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {
            actions
        } = this.props;
        this.props.form.validateFieldsAndScroll((err, values) => {
            const { linkData, user, isChecked } = this.props;
            if (!err) {
                const updatedValues = {
                    ...values,
                    link: linkData.upload_link_slug_hash,
                    userId: user?.UID,
                    s3LinkId: linkData?.id,
                };

                if (isChecked.isFileQueue) {
                    updatedValues.links_s3_checked = false;
                }
              
                actions.confirmS3UploadRequest(updatedValues);
                this.setState({
                    showSuccessModal: true,
                });
            }
        });

        this.setState({
            showModal: false,
        });
    };

    handleSuccessModalClose = () => {
        this.setState({
            showSuccessModal: false,
        });
        // eslint-disable-next-line no-undef
        setTimeout(() => window.location.reload(true), 500);
    }

    handleShowConfirm = () => {
        const { linkData } = this.props;
        if (linkData.upload_link_lock_on_complete) {
            this.setState({
                showModal: true,
            });
        } else {
            this.handleSubmit();
        }
    }

    handleCancel = () => {
        this.setState({
            showModal: false,
        });
    }

    hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field])

    render() {
        return this.view();
    }
}

LinkUploadForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const UploadForm = Form.create()(LinkUploadForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        isLoggedIn: state.user.isLoggedIn,
        actions: state.actions,
        isFetching: state.user.isFetching,
        user: state.user.user,
        linkData: state.s3Links.linkData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
            ...s3LinksActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadForm);
