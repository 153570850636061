import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

import DOM from './selectDay';

class SelectDay extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.counterInterval = null;
        this.state = {
            visibleProductionInfo: false,
            progressCounter: 0,
        };
    }

    componentWillMount() {
        const { actions, params, selectedProduction } = this.props;

        // if (params.production !== selectedProduction?.slug) {
        actions.getUserSingleProductionRequest({
            slug: params.production,
        });
        actions.toggleImageTagModeRequest(false);
        // }
    }

    componentDidMount() {
        this.startProgressCounter();
        if (this.state.progressCounter >= 100 && this.counterInterval !== null) {
            this.stopProgressCounter();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.progressCounter >= 100 && this.counterInterval !== null) || this.props.isFetching === false) {
            this.stopProgressCounter();
        }
    }

    startProgressCounter = () => {
        if (this.counterInterval === null) {
            console.log('========> startProgressCounter', this.counterInterval);
            this.counterInterval = setInterval(() => {
                this.setState({
                    progressCounter: this.state.progressCounter + 10,
                });
                console.log('Progress Counter:', this.state.progressCounter);
            }, 1000);
        }
    }

    stopProgressCounter = () => {
        if (this.counterInterval !== null) {
            clearInterval(this.counterInterval);
            this.counterInterval = null;
            this.setState({ progressCounter: 0 });
        }
    }

    render() {
        const { selectedProduction, isFetching, user } = this.props;
        return this.view({ selectedProduction, isFetching, user });
    }
}

SelectDay.defaultProps = {
    selectedProduction: {},
    isFetching: false,
    user: {},
    params: {},
};

SelectDay.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedProduction: PropTypes.object,
    isFetching: PropTypes.bool,
    user: PropTypes.object,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        isFetching: state.production.isFetching,
        user: state.user.user,
        selectedActor: state.production.selectedActor,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectDay);
