import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

import DOM from './allowanceWidget';

class AllowanceWidget extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            visibleProductionInfo: false,
        };
    }

    handleProductionInfoOk = () => {

    }

    handleProductionInfoCancel = () => {
        this.setState({
            visibleProductionInfo: false,
        });
    }

    toggleImageSelect = (productionSlug, productionDaySlug, imageName) => {
        const { actions } = this.props;
        actions.setImageSelectedFlagRequest({
            productionSlug,
            productionDaySlug,
            imageName,
        });
    }

    toggleImageCaptionMode() {
        const { actions, imageCaptionModeOn } = this.props;
        actions.toggleImageCaptionModeRequest(!imageCaptionModeOn);
    }

    toggleImageTagMode() {
        const { actions, imageTagModeOn } = this.props;
        actions.toggleImageTagModeRequest(!imageTagModeOn);
    }

    downloadImage(productionSlug, productionDaySlug, imageName) {
        const { actions } = this.props;
        actions.getDownloadImageRequest({
            productionSlug,
            productionDaySlug,
            imageName,
        });
    }

    render() {
        return this.view();
    }
}

AllowanceWidget.propTypes = {
    // selectedProduction: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    imageCaptionModeOn: PropTypes.bool.isRequired,
    imageTagModeOn: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        selectedProductionDayImage: state.production.selectedProductionDayImage,
        isFetching: state.production.isFetching,
        user: state.user.user,
        imageCaptionModeOn: state.global.imageCaptionModeOn,
        productionUserStats: state.production.productionUserStats,
        userStatsIsFetching: state.production.userStatsIsFetching,
        imageTagModeOn: state.global.imageTagModeOn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...productionActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceWidget);
