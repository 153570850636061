/* global window: true */
/* global document: true */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import {
    isUndefined, findIndex, map, each, isEmpty, filter, isNull
} from 'underscore';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

import DOM from './killList';
import { ROLE_ACTOR } from '../../core/utils/value';

const {
    APP_DEBUG,
    BASE_URL,
    BASE_URL_DEBUG,
} = require('../../core/constants').default;

class KillList extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            type: 'killed',
            killedDays: [],
            unkilledDays: [],
            selectedKilledDay: {},
            selectedUnkilledDay: {},
            currentRow: 0,
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        const { actions, params } = this.props;
        actions.getUserSingleProductionRequest({
            slug: params.production,
        });
        document.getElementById('kill-list-page').scrollTop = 0;
        this.filterKilledImages();
        this.filterUnkilledImages();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.selectedProduction !== prevProps.selectedProduction) {
            this.filterKilledImages();
            this.filterUnkilledImages();
        }
    }

    checkSelectedDay() {
        const { activeTab, activeDay } = this.props;
        const { killedDays, unkilledDays } = this.state;

        if (activeDay !== '') {
            if (activeTab === 'killed') {
                const dayIndex = findIndex(killedDays, (val) => val.slug === activeDay);
                if (dayIndex !== -1) {
                    this.setState({
                        selectedKilledDay: killedDays[dayIndex],
                    });
                }
            }

            if (activeTab === 'unkilled') {
                this.displayUnkilledTabNotification();
                const dayIndex = findIndex(unkilledDays, (val) => val.slug === activeDay);
                if (dayIndex !== -1) {
                    this.setState({
                        selectedUnkilledDay: unkilledDays[dayIndex],
                    });
                }
            }
        }
    }

    filterKilledImages() {
        const { selectedProduction, user, activeDay } = this.props;
        const { currentUser } = selectedProduction;
        const killedDays = [];

        map(selectedProduction.productionDays, (day) => {
            const images = [];
            let count = 0;
            each(day.images, (image) => {
                let isKilled = false;
                let status = 'killed';
                each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1) {
                        if (currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) === parseInt(view.user_id, 10)) {
                            isKilled = true;
                        } else if (currentUser.user_type !== ROLE_ACTOR) {
                            isKilled = true;
                        }
                    }
                });

                if (parseInt(image.status, 10) === 2) {
                    isKilled = true;
                    status = 'removed';
                }

                if (isKilled) {
                    const imageLink = `/production/${selectedProduction.slug}/${day.slug}/${image.name}/kill-list`;
                    images[count] = {
                        id: image.id,
                        picUrl: `${APP_DEBUG ? BASE_URL_DEBUG : BASE_URL}/display-image?key=${image.publicUrl}&thumb=true`,
                        status,
                        hasImageActiveCaption: image.hasImageActiveCaption,
                        link: imageLink,
                        isSelected: image.is_selected,
                        isRemoved: parseInt(image.status, 10) === 2,
                    };
                    count += 1;
                }
            });

            const dayData = {
                id: parseInt(day.id, 10),
                name: day.name,
                slug: day.slug,
                images,
            };
            killedDays.push(dayData);
        });

        // setting the active day
        let newSelectedKilledDay = killedDays[0] ? killedDays[0] : {};
        const dayIndex = findIndex(killedDays, (val) => val.slug === activeDay);
        if (dayIndex !== -1) {
            newSelectedKilledDay = killedDays[dayIndex];
        }

        this.setState({
            killedDays,
            selectedKilledDay: newSelectedKilledDay,
        });
    }

    filterUnkilledImages() {
        const { selectedProduction, user, activeDay } = this.props;
        const { currentUser } = selectedProduction;
        const unkilledDays = [];

        map(selectedProduction.productionDays, (day) => {
            const images = [];
            let count = 0;

            // adding images only if all actors marked day as done
            if (day.markedDoneActor) {
                each(day.images, (image) => {
                    let isKilled = false;
                    each(image.views, (view) => {
                        if (parseInt(view.killed, 10) === 1) {
                            if ((currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) === parseInt(view.user_id, 10)) || currentUser.user_type !== ROLE_ACTOR) {
                                isKilled = true;
                            }
                        }
                    });

                    if (parseInt(image.status, 10) === 2) {
                        isKilled = true;
                    }

                    let status = 'disabled';
                    if (isKilled) {
                        status = 'killed';
                    } else {
                        // checking did this user viewed the image or not
                        const userViewRecord = filter(image.views, (view) => parseInt(view.user_id, 10) === user.UID);
                        if (userViewRecord[0] && userViewRecord[0].viewed_on && !isNull(userViewRecord[0].viewed_on)) {
                            status = 'viewed';
                        }
                    }

                    if (!isKilled) {
                        const imageLink = `/production/${selectedProduction.slug}/${day.slug}/${image.name}/not-killed`;
                        images[count] = {
                            id: image.id,
                            picUrl: `${APP_DEBUG ? BASE_URL_DEBUG : BASE_URL}/display-image?key=${image.publicUrl}&thumb=true`,
                            status,
                            hasImageActiveCaption: image.hasImageActiveCaption,
                            isSelected: image.is_selected,
                            link: imageLink,
                            isRemoved: parseInt(image.status, 10) === 2,
                        };
                        count += 1;
                    }
                });

                if (!isEmpty(images)) {
                    const dayData = {
                        id: parseInt(day.id, 10),
                        name: day.name,
                        slug: day.slug,
                        images,
                    };
                    unkilledDays.push(dayData);
                }
            }
        });

        // setting the active day
        let newSelectedKilledDay = unkilledDays[0] ? unkilledDays[0] : {};
        const dayIndex = findIndex(unkilledDays, (val) => val.slug === activeDay);
        if (dayIndex !== -1) {
            newSelectedKilledDay = unkilledDays[dayIndex];
        }

        this.setState({
            unkilledDays,
            selectedUnkilledDay: newSelectedKilledDay,
        });
    }

    changeActiveTab(key) {
        const { actions } = this.props;
        actions.saveImagesListPositionRequest(0);
        const { productionSlug } = this.props;
        const { killedDays, unkilledDays } = this.state;

        let activeDay = '';
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const previousActiveDay = pathParts[pathParts.length - 1];

        if (key === 'killed') {
            if (killedDays.length > 0) {
                const matchingDay = killedDays.find(day => day.slug === previousActiveDay);
                activeDay = matchingDay ? matchingDay.slug : killedDays[0].slug;
            }
        } else if (key === 'unkilled') {
            if (unkilledDays.length > 0) {
                const matchingDay = unkilledDays.find(day => day.slug === previousActiveDay);
                activeDay = matchingDay ? matchingDay.slug : unkilledDays[0].slug;
            }
        }
        // Set the selected day based on the active tab
        if (key === 'killed') {
            this.setState({
                selectedKilledDay: killedDays.find(day => day.slug === activeDay) || killedDays[0] || {}
            });
        } else if (key === 'unkilled') {
            this.setState({
                selectedUnkilledDay: unkilledDays.find(day => day.slug === activeDay) || unkilledDays[0] || {}
            });
        }

        // Update the URL to reflect the correct tab and active day
        window.history.replaceState(
            null,
            '',
            `/production/${productionSlug}/kill-list/${key}/${activeDay}`
        );

        if (key === 'unkilled') {
            this.displayUnkilledTabNotification();
        }
        this.setState({ type: key });
    }

    displayUnkilledTabNotification() {
        const { user, selectedProduction } = this.props;
        const { currentUser } = selectedProduction;

        let unkilledTabMessage = 'Showing all unkilled images for days that all actors have marked as done';
        if (currentUser.user_type === ROLE_ACTOR) {
            unkilledTabMessage = 'Showing all your unkilled images for days that you  have marked as done';
        }

        notification.open({
            placement: 'bottomLeft',
            description: unkilledTabMessage,
            key: 'unkilled-notification',
            duration: 7,
        });
    }

    saveCurrentRowPosition(data) {
        const { actions } = this.props;
        if (data.currentRow) {
            actions.saveImagesListPositionRequest(data.currentRow);
        }
    }

    changeActiveKilledDay(dayId) {
        const { killedDays } = this.state;
        const { productionSlug, actions } = this.props;

        const dayIndex = findIndex(killedDays, (val) => val.id === dayId);
        if (dayIndex !== -1) {
            // changing the url on the browser but not actually redirecting
            window.history.replaceState('Killed list page', 'Kills list', `/production/${productionSlug}/kill-list/killed/${killedDays[dayIndex].slug}`);

            actions.saveImagesListPositionRequest(0);
            this.setState({
                selectedKilledDay: killedDays[dayIndex],
            });
        }
    }

    changeActiveUnkilledDay(dayId) {
        const { unkilledDays } = this.state;
        const { productionSlug, actions } = this.props;

        const dayIndex = findIndex(unkilledDays, (val) => val.id === dayId);
        if (dayIndex !== -1) {
            // changing the url on the browser but not actually redirecting
            window.history.replaceState('Killed list page', 'Kills list', `/production/${productionSlug}/kill-list/unkilled/${unkilledDays[dayIndex].slug}`);

            actions.saveImagesListPositionRequest(0);
            this.setState({
                selectedUnkilledDay: unkilledDays[dayIndex],
            });
        }
    }

    render() {
        return this.view();
    }
}

KillList.defaultProps = {
    activeTab: 'killed',
    activeDay: '',
    productionSlug: '',
    params: {},
    user: {},
    selectedProduction: {},
};

KillList.propTypes = {
    actions: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    activeDay: PropTypes.string,
    productionSlug: PropTypes.string,
    params: PropTypes.object,
    user: PropTypes.object,
    selectedProduction: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    let activeTab = 'killed';
    let activeDay = '';
    let productionSlug = '';

    if (!isUndefined(ownProps.params.activeTab) && ownProps.params.activeTab === 'unkilled') {
        activeTab = ownProps.params.activeTab;
    }

    if (!isUndefined(ownProps.params.activeDay)) {
        activeDay = ownProps.params.activeDay;
    }

    if (!isUndefined(ownProps.params.production)) {
        productionSlug = ownProps.params.production;
    }

    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction || {},
        user: state.user.user,
        activeTab,
        activeDay,
        productionSlug,
        imagesListPosition: state.global.imagesListPosition,
        isFetching: state.production.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(KillList);
