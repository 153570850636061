import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    REHYDRATION_COMPLETED: null,
    SET_VERSION: null,
    SET_BREADCRUMB: null,

    SAVE_IMAGES_LIST_POSITION_REQUEST: null,

    TOGGLE_IMAGE_CAPTION_MODE_REQUEST: null,

    TOGGLE_IMAGE_TAG_MODE_REQUEST: null,
});

export const {
    setVersion,
    setBreadcrumb,
    rehydrationCompleted,
} = createActions(constants.SET_VERSION, constants.SET_BREADCRUMB, constants.REHYDRATION_COMPLETED);

export const {
    saveImagesListPositionRequest,
} = createActions(
    constants.SAVE_IMAGES_LIST_POSITION_REQUEST,
);

export const {
    toggleImageCaptionModeRequest,
} = createActions(
    constants.TOGGLE_IMAGE_CAPTION_MODE_REQUEST,
);

export const {
    toggleImageTagModeRequest,
} = createActions(
    constants.TOGGLE_IMAGE_TAG_MODE_REQUEST,
);
