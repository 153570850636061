/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
    Col, Row, Progress, Divider,
} from 'antd';
import { map, sortBy } from 'underscore';
import { ALL_SELECTS_IMAGES, ALL_UNKILLED_IMAGES, ALL_UNKILLED_SELECTS_IMAGES, ROLE_ACTOR, ALL_IMAGES, ROLE_ADMIN, ROLE_RESELLER, ROLE_AGENT, ROLE_VIEWER, ROLE_ROOT_ADMIN, ROLE_RESELLER_ADMIN } from '../../core/utils/value';
import './allowanceWidget.scss';

function ModalContent(props) {
    const {
        type,
        currentUser,
        userSoloTotalImages,
        userGroupTotalImages,
        userLowerGroupTotalImages,
        productionTotalImages,
        killedImagesLink,
        filteredUserStats,
        userUpperGroupTotalImages,
        approveSelectedImages,
        totalUnkilledImages,
    } = props;

    let sortedUserStats = sortBy(filteredUserStats, 'name').reverse();
    sortedUserStats = sortBy(sortedUserStats, 'userType').reverse();

    const sortedActorStats = sortedUserStats.filter((userStat) => userStat.userType === ROLE_ACTOR);
    const sortedNonActorStats = sortedUserStats.filter((userStat) => userStat.userType !== ROLE_ACTOR);

    const soloAndGroupActors = sortedActorStats.filter((userStat) => userStat.allowanceType === 1);
    const soloUpperLowerActors = sortedActorStats.filter((userStat) => userStat.allowanceType === 2);

    const chartGreenColor = '#52c41a';
    const chartBlueColor = '#1890ff';
    const chartRedColor = '#c00000';

    const upperUsers = 0;
    switch (type) {
    case 'user': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;
        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = totalAllowedGroupKillImages > 0
            ? Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages) * 100)
            : 0;

        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' You have used '}
                            <strong>{currentUser.soloKills}</strong>
                            {' ('}
                            {soloKillAllowanceUsedPercentage}
                            {'%) of your available solo kill allowance of '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' images, meaning you have '}
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' images ('}
                            {soloKillAllowanceRemainingPercentage}
                            {'%) remaining that you can kill within your solo kill allowance.'}
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Group:</strong>
                            {' You have used '}
                            <strong>{currentUser.groupKills}</strong>
                            {' ('}
                            {groupKillAllowanceUsedPercentage}
                            {'%) of your available group kill allowance of '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' images, meaning you have '}
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' images ('}
                            {groupKillAllowanceRemainingPercentage}
                            {'%) remaining that you can kill within your group kill allowance.'}
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            {'You appear in a total of '}
                            <strong>{currentUser.userTotalImages}</strong>
                            {' images across this production. '}
                            <strong>{currentUser.userSoloImages}</strong>
                            {' of these images are solo images, and '}
                            <strong>{currentUser.userGroupImages}</strong>
                            {' are group images. You may kill up to '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' solo images, and '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' group images in total.'}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'upper': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;

        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = totalAllowedGroupKillImages > 0
            ? Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages) * 100)
            : 0;
    
        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        const totalAllowedUpperKillImages = Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages);
        const remainingAllowedUpperKillImages = totalAllowedUpperKillImages > 0
            ? totalAllowedUpperKillImages - currentUser.upperGroupKills
            : 0;
        const upperKillAllowanceUsedPercentage = totalAllowedUpperKillImages > 0
            ? Math.ceil(currentUser.upperGroupKills / Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages) * 100)
            : 0;
        const upperKillAllowanceRemainingPercentage = 100 - upperKillAllowanceUsedPercentage;

        let upperColor = chartGreenColor;
        if (upperKillAllowanceUsedPercentage >= 30 && upperKillAllowanceUsedPercentage < 70) {
            upperColor = chartBlueColor;
        } else if (upperKillAllowanceUsedPercentage >= 70) {
            upperColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Lower Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <h2>Upper Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={upperColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={upperKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedUpperKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.upperGroupKills || 0}
                                            /
                                            {totalAllowedUpperKillImages}
                                            {' '}
                                            (
                                            {upperKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' You have used '}
                            <strong>{currentUser.soloKills}</strong>
                            {' ('}
                            {soloKillAllowanceUsedPercentage}
                            {'%) of your available solo kill allowance of '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' images, meaning you have '}
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' images ('}
                            {soloKillAllowanceRemainingPercentage}
                            {'%) remaining that you can kill within your solo kill allowance.'}
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Lower Group:</strong>
                            {' You have used '}
                            <strong>{currentUser.groupKills}</strong>
                            {' ('}
                            {groupKillAllowanceUsedPercentage}
                            {'%) of your available lower group kill allowance of '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' images, meaning you have '}
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' images ('}
                            {groupKillAllowanceRemainingPercentage}
                            {'%) remaining that you can kill within your lower group kill allowance.'}
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Upper Group:</strong>
                            {' You have used '}
                            <strong>{currentUser.upperGroupKills || 0}</strong>
                            {' ('}
                            {upperKillAllowanceUsedPercentage}
                            {'%) of your available upper group kill allowance of '}
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' images, meaning you have '}
                            <strong>{remainingAllowedUpperKillImages}</strong>
                            {' images ('}
                            {upperKillAllowanceRemainingPercentage}
                            {'%) remaining that you can kill within your upper group kill allowance.'}
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            {'You appear in a total of '}
                            <strong>{productionTotalImages}</strong>
                            {' images across this production. '}
                            <strong>{userSoloTotalImages}</strong>
                            {' of these images are solo images, '}
                            <strong>{userLowerGroupTotalImages}</strong>
                            {' are lower group images, and '}
                            <strong>{userUpperGroupTotalImages}</strong>
                            {' are upper group images. You may kill up to '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' solo images, up to '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' lower group images, and '}
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' upper group images in total.'}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }

    case 'agent': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center', maxWidth: '1000px', width: '100%', overflowX: 'auto', margin: '0 auto', paddingTop: 20, paddingBottom: 20 }}>
                <Row>
                    <div className="table-container">
                        <table className="fixed-width">
                            <thead>
                                <tr>
                                    <th>Total Images</th>
                                    <th>Total Unkilled</th>
                                    <th>Total Unkilled Selects</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{productionTotalImages}</td>
                                    <td>{approveSelectedImages}</td>
                                    <td>{totalUnkilledImages}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
                {soloAndGroupActors.length > 0 && (
                    <div>
                        <Row style={{ marginTop: 15, marginBottom: 15 }}>
                            <Col xs={24}>
                                <h4 style={{ marginBottom: 5 }}>Kill Allowance mechanism</h4>
                                <h2>Solo & Group</h2>
                            </Col>
                        </Row>
                        <Row className="table-row">
                            <h3>Total Images / Selects</h3>
                            <div className="table-container">
                                <table className="fixed-width">
                                    <thead>
                                        <tr>
                                            <th>Talent</th>
                                            <th>Total</th>
                                            <th>Selects</th>
                                            <th className="dark-left-border">Solo</th>
                                            <th>Selects</th>
                                            <th className="dark-left-border">Group</th>
                                            <th>Selects</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.userSoloImages + userStat.userGroupImages}</td>
                                                <td>{userStat.userSoloSelectedImages + userStat.userGroupSelectedImages}</td>
                                                <td className="dark-left-border">{userStat.userSoloImages}</td>
                                                <td>{userStat.userSoloSelectedImages}</td>
                                                <td className="dark-left-border">{userStat.userGroupImages}</td>
                                                <td>{userStat.userGroupSelectedImages}</td>
                                            </tr>
                                        ) : null))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                        <Row className="table-row">
                            <h3>Kill Allowance</h3>
                            <div className="table-container">
                                <table className="fixed-width">
                                    <thead>
                                        <tr>
                                            <th>Talent</th>
                                            <th>Kill Allowance Solo</th>
                                            <th className="dark-left-border">Kill Allowance Group</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.soloKillLimit === 0 ? 'Unlimited' : `${userStat.soloKilled} of ${Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)} (${Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}%)`}</td>
                                                <td className="dark-left-border">{userStat.groupKillLimit === 0 ? 'Unlimited' : `${userStat.groupKilled} of ${Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)} (${Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}%)`}</td>
                                            </tr>
                                        ) : null))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                        <Row className="table-row">
                            <h3>Unkilled Images / Selects</h3>
                            <div className="table-container">
                                <table className="fixed-width">
                                    <thead>
                                        <tr>
                                            <th>Talent</th>
                                            <th>Unkilled Solo</th>
                                            <th>Selects</th>
                                            <th className="dark-left-border">Unkilled Group</th>
                                            <th>Selects</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(sortedActorStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.soloUnkilled}</td>
                                                <td>{userStat.userSoloUnkilledSelects}</td>
                                                <td className="dark-left-border">{userStat.groupUnkilled}</td>
                                                <td>{userStat.userGroupUnkilledSelects}</td>
                                            </tr>
                                        ) : null))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </div>
                )}

                {soloUpperLowerActors.length > 0 && (
                    <div>
                        <Row style={{ marginTop: 15, marginBottom: 15, width: '100%', textAlign: 'center' }}>
                            <Col xs={24}>
                                <h4 style={{ marginBottom: 5 }}>Kill Allowance mechanism</h4>
                                <h2 style={{ flexWrap: 'wrap', width: 250, margin: '0 auto' }}>Solo, lower group, upper group</h2>
                            </Col>
                        </Row>
                        <Row className="table-row">
                            <h3>Total images</h3>
                            <div className="table-container">
                                <table className="fixed-width">
                                    <thead>
                                        <tr>
                                            <th>Talent</th>
                                            <th>Total</th>
                                            <th>Solo</th>
                                            <th>Group</th>
                                            <th>Group Upper</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(soloUpperLowerActors, (userStat, userStatIndex) => (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.userSoloImages + userStat.userGroupImages}</td>
                                                <td>{userStat.userSoloImages}</td>
                                                <td>{userStat.userGroupImages}</td>
                                                <td>{userStat.userUpperGroupImages}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                        <Row className="table-row">
                            <h3>Kill allowance</h3>
                            <div className="table-container">
                                <table className="fixed-width">
                                    <thead>
                                        <tr>
                                            <th>Talent</th>
                                            <th>Solo</th>
                                            <th>Group</th>
                                            <th>Group Upper</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(soloUpperLowerActors, (userStat, userStatIndex) => (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.soloKillLimit === 0 ? 'Unlimited' : `${userStat.soloKilled} of ${Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)} (${Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}%)`}</td>
                                                <td>{userStat.groupKillLimit === 0 ? 'Unlimited' : `${userStat.groupKilled} of ${Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)} (${Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}%)`}</td>
                                                <td>{userStat.upperGroupKillLimit === 0 ? 'Unlimited' : `${userStat.upperGroupKilled} of ${Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)} (${Math.ceil(userStat.upperGroupKilled / ((userStat.upperGroupKillLimit === 0 ? 1 : Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)) || 1) * 100)}%)`}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </div>
                )}

                {sortedNonActorStats.length > 0 && (
                    <div>
                        <Row style={{ marginTop: 25, marginBottom: 15 }}>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>User Type</th>
                                            <th>Can View</th>
                                            <th>Watermarks</th>
                                            <th className="lg">Downloads</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(sortedNonActorStats, (userStat, userStatIndex) => (
                                            <tr key={userStatIndex}>
                                                <td className="darker-background">{userStat.name}</td>
                                                <td>{userStat.formattedUserType}</td>
                                                <td>{userStat.canView}</td>
                                                <td>{userStat.showWatermark}</td>
                                                <td className="lg">{userStat.formattedAllowImageDownload}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </div>
                )}

                <Row style={{ marginTop: '20px' }}>
                    <Col xs={24}>
                        <Link to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'viewer': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center' }}>
                {currentUser.SelectImageViewOption === ALL_UNKILLED_IMAGES && (
                    soloAndGroupActors.length > 0 ? (
                        <div style={{ width: '750px', margin: '0 auto' }}>
                            <Row>
                                <div className="table-row">
                                    <h3>Unkilled</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Total</th>
                                                    <th>Solo</th>
                                                    <th>Group</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.soloUnkilled + userStat.groupUnkilled}</td>
                                                        <td>{userStat.soloUnkilled}</td>
                                                        <td>{userStat.groupUnkilled}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <Row style={{ width: '750px', margin: '0 auto' }}>
                            <Col xs={24}>
                                <p>No actors in this production.</p>
                            </Col>
                        </Row>
                    )
                )}
                {currentUser.SelectImageViewOption === ALL_SELECTS_IMAGES && (
                    soloAndGroupActors.length > 0 ? (
                        <div style={{ width: '750px', margin: '0 auto' }}>
                            <Row>
                                <div className="table-row">
                                    <h3>All Selects</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Total</th>
                                                    <th>Solo</th>
                                                    <th>Group</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.userSoloImages + userStat.userGroupImages}</td>
                                                        <td>{userStat.userSoloImages}</td>
                                                        <td>{userStat.userGroupImages}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="table-row">
                                    <h3>Unkilled Selects</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Total</th>
                                                    <th>Solo</th>
                                                    <th>Group</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.userSoloUnkilledSelects + userStat.userGroupUnkilledSelects}</td>
                                                        <td>{userStat.userSoloUnkilledSelects}</td>
                                                        <td>{userStat.userGroupUnkilledSelects}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <Row style={{ width: '750px', margin: '0 auto' }}>
                            <Col xs={24}>
                                <p>No actors in this production.</p>
                            </Col>
                        </Row>
                    )
                )}
                {currentUser.SelectImageViewOption === ALL_UNKILLED_SELECTS_IMAGES && (
                    soloAndGroupActors.length > 0 ? (
                        <div style={{ width: '750px', margin: '0 auto' }}>
                            <Row>
                                <div className="table-row">
                                    <h3>Unkilled Selects</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Total</th>
                                                    <th>Solo</th>
                                                    <th>Group</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.userSoloUnkilledSelects + userStat.userGroupUnkilledSelects}</td>
                                                        <td>{userStat.userSoloUnkilledSelects}</td>
                                                        <td>{userStat.userGroupUnkilledSelects}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <Row style={{ width: '750px', margin: '0 auto' }}>
                            <Col xs={24}>
                                <p>No actors in this production.</p>
                            </Col>
                        </Row>
                    )
                )}
                {currentUser.SelectImageViewOption === ALL_IMAGES && (
                    soloAndGroupActors.length > 0 ? (
                        <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center', maxWidth: '1000px', width: '100%', overflowX: 'auto', margin: '0 auto', paddingTop: 20, paddingBottom: 20 }}>
                            <Row>
                                <div className="table-row">
                                    <h3>Total Images / Selects</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Total</th>
                                                    <th>Selects</th>
                                                    <th className="dark-left-border">Solo</th>
                                                    <th>Selects</th>
                                                    <th className="dark-left-border">Group</th>
                                                    <th>Selects</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.userSoloImages + userStat.userGroupImages}</td>
                                                        <td>{userStat.userSoloSelectedImages + userStat.userGroupSelectedImages}</td>
                                                        <td className="dark-left-border">{userStat.userSoloImages}</td>
                                                        <td>{userStat.userSoloSelectedImages}</td>
                                                        <td className="dark-left-border">{userStat.userGroupImages}</td>
                                                        <td>{userStat.userGroupSelectedImages}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                                <div className="table-row">
                                    <h3>Unkilled Images / Selects</h3>
                                    <div className="table-container">
                                        <table className="fixed-width">
                                            <thead>
                                                <tr>
                                                    <th>Talent</th>
                                                    <th>Solo</th>
                                                    <th>Selects</th>
                                                    <th className="dark-left-border">Group</th>
                                                    <th>Selects</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(soloAndGroupActors, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                                    <tr key={userStatIndex}>
                                                        <td className="darker-background">{userStat.name}</td>
                                                        <td>{userStat.soloUnkilled}</td>
                                                        <td>{userStat.userSoloUnkilledSelects}</td>
                                                        <td className="dark-left-border">{userStat.groupUnkilled}</td>
                                                        <td>{userStat.userGroupUnkilledSelects}</td>
                                                    </tr>
                                                ) : null))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <Row style={{ width: '750px', margin: '0 auto' }}>
                            <Col xs={24}>
                                <p>No actors in this production.</p>
                            </Col>
                        </Row>
                    )
                )}
                {currentUser.allow === 'none' && (
                    <Row>
                        <Col xs={24}>
                            <p>
                                You do not have access to any images.
                            </p>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
    default:
        return null;
    }
}

ModalContent.defaultProps = {
    killedImagesLink: null,
};

ModalContent.propTypes = {
    type: PropTypes.string.isRequired,
    currentUser: PropTypes.any.isRequired,
    userSoloTotalImages: PropTypes.any.isRequired,
    userGroupTotalImages: PropTypes.any.isRequired,
    productionTotalImages: PropTypes.any.isRequired,
    killedImagesLink: PropTypes.any,
    filteredUserStats: PropTypes.any.isRequired,
    userUpperGroupTotalImages: PropTypes.any.isRequired,
    userLowerGroupTotalImages: PropTypes.any.isRequired,
};

export default ModalContent;
