import React from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';

import './linkUploadForm.css';

const FormItem = Form.Item;
const { TextArea } = Input;

export default function () {
    const { getFieldDecorator } = this.props.form;
    const { showModal, showSuccessModal } = this.state;

    return (
        <div className="linkUploadForm-wrap">
            <Form layout="vertical">
                <FormItem 
                    label="Note" 
                    style={{
                        fontWeight: 'bold',
                    }}>
                    {getFieldDecorator('note', {
                        rules: [
                            {
                                required: false,
                            }
                        ],
                        initialValue: false,
                    })(<TextArea rows={8} placeholder="Leave any notes here" />)}
                </FormItem>
                <FormItem
                    style={{
                        marginTop: 48
                    }}>
                    <Checkbox onChange={this.props.onCheckedChanged}>Automatically confirm package and send notes once all uploads complete successfully.</Checkbox>
                </FormItem>
                <FormItem>
                    <Button disabled={!this.props.isLastFileQueue} style={{ width: '100%' }} type="red" onClick={this.handleShowConfirm}>
                        Confirm Upload Complete and Send Note
                    </Button>
                </FormItem>
            </Form>
            <Modal
                title="Are you sure?"
                centered
                visible={showModal}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}>
                <p>The upload link will be closed and you won't be able to upload anymore files once it is marked as sent.</p>
                <p>(Please make sure all files are uploaded correctly!)</p>
            </Modal>
            <Modal
                centered
                visible={showSuccessModal}
                onOk={this.handleSuccessModalClose}
                cancelButtonProps={{ style: { display: 'none' } }}>
                <p>Success! Your file uploads and note have been sent!</p>
            </Modal>
        </div>
    );
}
